import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import i18n from 'utils/i18n';

const SEO = ({ description, lang, meta, title })=> {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title {
              en
              pl
            }
            description
            author
            keywords
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title[i18n.language.substr(0, 2)]}`}
      meta={[
        {
          name: 'keywords',
          content: site.siteMetadata.keywords
        },
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(meta)} >
      <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap&subset=latin-ext" rel="stylesheet"></link>
    </Helmet>

  );
};

SEO.defaultProps = {
  lang: 'pl',
  meta: [],
  description: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default withTranslation()(SEO);
